import rawTimeZones from '@vvo/tzdb/raw-time-zones.json';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

const timeZones = rawTimeZones.map((tz) => ({
  label: tz.rawFormat,
  value: tz.name,
}));

const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

function getRoundedCanvas(sourceCanvas) {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var width = sourceCanvas.width;
  var height = sourceCanvas.height;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(
    width / 2,
    height / 2,
    Math.min(width, height) / 2,
    0,
    2 * Math.PI,
    true
  );
  context.fill();
  return canvas;
}

function setProfileCropDisplay(value) {
  document
    .getElementById('profile-crop')
    .style.setProperty('display', value, 'important');
}
function setProfilePreviewDisplay(value) {
  document
    .getElementById('profile-preview')
    .style.setProperty('display', value, 'important');
}

function resetPreview() {
  document.getElementById('preview-profile-image').innerHTML =
    initialProfileImage;
  document.getElementById('avatarInput').value = '';
}

function updatePreviewImage(src) {
  document.getElementById('preview-profile-image').innerHTML = `
    <div class="d-flex justify-content-center">
      <img id="image" style="width: 200px; height: 200px" src="${src}">
    </div>
  `;
}

function createCropper() {
  const image = document.getElementById('image');

  if (!cropper) {
    cropper = new Cropper(image, {
      dragMode: 'move',
      aspectRatio: 1,
      viewMode: 1,
      modal: true,
      responsive: true,
      autoCropArea: 1,
      restore: false,
      guides: false,
      center: true,
      highlight: true,
      minCanvasHeight: 150,
      minCanvasWeight: 150,
      cropBoxMovable: true,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      zoomOnWheel: false,
      ready: function () {
        this.cropper.zoomTo(0);

        var imageData = this.cropper.getImageData();

        var minSliderZoom = imageData.width / imageData.naturalWidth;

        const zoomSlider = document.getElementById('zoom-slider');

        zoomSlider.setAttribute('max', 3);
        zoomSlider.setAttribute('min', minSliderZoom);
        zoomSlider.setAttribute('value', minSliderZoom);
        zoomSlider.setAttribute('step', '0.0001');

        const rotationSlider = document.getElementById('rotation-slider');

        rotationSlider.setAttribute('max', 360);
        rotationSlider.setAttribute('min', 0);
        rotationSlider.setAttribute('value', 0);
        rotationSlider.setAttribute('step', '10');
      },
    });
  }
}

let cropper;
let avatarChanged = false;

const firstName = document.getElementById('first_name').value;
const lastName = document.getElementById('last_name').value;
const initials = getInitials(`${firstName}  ${lastName}`);

function getInitials(name) {
  const nameArray = name.split(' ');
  const firstNameIn = nameArray[0].charAt(0).toUpperCase();
  const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  return firstNameIn + lastNameIn;
}

const defaultProfileImage = `
  <div class="default-profile-picture" style="height: 200px; width:200px; font-size: 60px">
    ${initials}
  </div>
`;
const imageElement = document.getElementById('image');

let initialProfileImage =
  imageElement.value || imageElement.src
    ? document.getElementById('preview-profile-image').innerHTML
    : defaultProfileImage;

function setCropImage(file) {
  const previewImage = URL.createObjectURL(file);
  imageElement.setAttribute('src', previewImage);
  if (cropper) {
    cropper.replace(previewImage);
  }
}

function syncZoomValue() {
  const zoomValue = document.getElementById('zoom-slider').value;
  cropper.zoomTo(zoomValue);
}

function syncRotationValue() {
  const zoomValue = document.getElementById('rotation-slider').value;
  cropper.rotateTo(zoomValue);
}

$('#avatarModal').on('hide.bs.modal', function () {
  setProfilePreviewDisplay('block');
  setProfileCropDisplay('none');
  avatarChanged = false;
  document.getElementById('preview-profile-image').innerHTML =
    initialProfileImage;
});

$('#avatarModal').on('shown.bs.modal', function () {
  setProfileCropDisplay('none');
  initialProfileImage =
    imageElement.value || imageElement.src
      ? document.getElementById('preview-profile-image').innerHTML
      : defaultProfileImage;

  const input = document.getElementById('avatarInput');

  const uploadButton = document.getElementById('upload-avatar-button');

  uploadButton.addEventListener('click', function () {
    input.value = null;
    input.files = null;
  });

  input.addEventListener('change', function () {
    let input = this.files[0];

    if (!input) return;

    setCropImage(input);
    createCropper();

    const fileTooBig = input.size > MAX_IMAGE_SIZE;

    document.getElementById('large-file-error').style.display = fileTooBig
      ? 'block'
      : 'none';

    if (!fileTooBig) {
      setProfilePreviewDisplay('none');
      setProfileCropDisplay('block');
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  for (const element of document.getElementsByClassName(
    'default-profile-picture'
  )) {
    element.innerHTML = initials;
  }

  const zoomOut = document.getElementById('zoom-out');
  const zoomIn = document.getElementById('zoom-in');
  const zoomSlider = document.getElementById('zoom-slider');

  zoomSlider.addEventListener('input', syncZoomValue);

  zoomOut.addEventListener('click', function () {
    zoomSlider.value = parseFloat(zoomSlider.value) - 0.01;
    syncZoomValue();
  });

  zoomIn.addEventListener('click', function () {
    zoomSlider.value = parseFloat(zoomSlider.value) + 0.01;
    syncZoomValue();
  });

  const rotateCounterCW = document.getElementById('rotate-counter-cw');
  const rotateCW = document.getElementById('rotate-cw');
  const rotationSlider = document.getElementById('rotation-slider');

  rotationSlider.addEventListener('input', syncRotationValue);

  rotateCounterCW.addEventListener('click', function () {
    rotationSlider.value = parseFloat(rotationSlider.value) - 10;
    syncRotationValue();
  });

  rotateCW.addEventListener('click', function () {
    rotationSlider.value = parseFloat(rotationSlider.value) + 10;
    syncRotationValue();
  });

  const cancelCropButton = document.getElementById('cropCancel');
  const submitButton = document.getElementById('submit-avatar');
  const clearButton = document.getElementById('clear-preview-image');
  const cropCompleted = document.getElementById('cropDone');

  clearButton.addEventListener('click', function () {
    document.getElementById('preview-profile-image').innerHTML =
      defaultProfileImage;
    document.getElementById('avatarUpload').files = null;
    document.getElementById('avatarUpload').value = null;
    avatarChanged = true;
  });

  submitButton.addEventListener('click', function () {
    if (avatarChanged) {
      document.getElementById('avatarForm').submit();
    }

    $('#avatarModal').modal('hide');
  });

  cancelCropButton.addEventListener('click', function () {
    resetPreview();
    setProfilePreviewDisplay('block');
    setProfileCropDisplay('none');
  });

  cropCompleted.addEventListener('click', function () {
    const croppedCanvas = cropper.getCroppedCanvas();
    const roundedCanvas = getRoundedCanvas(croppedCanvas);
    const roundedImageSrc = roundedCanvas.toDataURL();

    roundedCanvas.toBlob((blob) => {
      const dT = new DataTransfer();
      dT.items.add(
        new File([blob], `avatar-${crypto.randomUUID()}.png`, {
          type: 'image/png',
        })
      );

      document.getElementById('avatarUpload').files = dT.files;
      avatarChanged = true;

      updatePreviewImage(roundedImageSrc);

      setProfilePreviewDisplay('block');
      setProfileCropDisplay('none');
    }, 'image/png');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const timeZoneSelector = document.querySelector('#user_time_zone');

  const browserDetectedTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';

  if (timeZoneSelector) {
    timeZoneSelector.innerHTML = '';

    let fragment = document.createDocumentFragment();
    timeZones.forEach((tz) => {
      let opt = document.createElement('option');
      opt.innerHTML = tz.label;
      opt.value = tz.value;
      fragment.appendChild(opt);
    });
    timeZoneSelector.appendChild(fragment);
    const userTimeZone = timeZoneSelector.dataset.userTimeZone;
    timeZoneSelector.value = userTimeZone || browserDetectedTimezone;
  } else {
    document.getElementById('user_time_zone_signin').value =
      browserDetectedTimezone;
  }
});
